var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"dark":"","flat":"","height":"52px","color":"clientLayout"}},[_c('v-toolbar-title',{staticClass:"white--text headline font-weight-normal  ml-10"},[_c('div',[_vm._v(" "+_vm._s(_vm.headline)+" ")])]),(
          _vm.dataChanged === true &&
            _vm.loadingSuccess == false &&
            _vm.showButton == true
        )?[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text caption ml-2",attrs:{"color":"success","fab":"","depressed":"","x-small":""},on:{"click":_vm.updateItem}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-cloud-upload ")])],1)]}}],null,false,2863620554)},[_c('span',[_vm._v("Save your changes")])])]:_vm._e(),(_vm.loadingSuccess == true)?[_c('v-icon',{staticClass:"mt-n2 ml-2",attrs:{"large":"","color":"green"}},[_vm._v("mdi-check")]),_c('span',{staticClass:"white--text"},[_vm._v("saved")])]:_vm._e(),_c('v-spacer'),_c('v-toolbar-title',{staticClass:"white--text headline font-weight-normal  mr-12"},[(_vm.clientProfile.type == 'Individual')?_c('div',[_vm._v(" "+_vm._s(_vm.clientAccHolder1)+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.clientName)+" ")])])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }