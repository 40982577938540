<template>
  <div>
    <v-card flat>
      <v-toolbar dark flat height="52px" color="clientLayout">
        <v-toolbar-title class="white--text headline font-weight-normal  ml-10"
          ><div>
            {{ headline }}
          </div>
        </v-toolbar-title>

        <template
          v-if="
            dataChanged === true &&
              loadingSuccess == false &&
              showButton == true
          "
        >
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="success"
                class="white--text caption ml-2"
                fab
                depressed
                x-small
                @click="updateItem"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon dark> mdi-cloud-upload </v-icon>
              </v-btn>
            </template>
            <span>Save your changes</span>
          </v-tooltip>
        </template>
        <template v-if="loadingSuccess == true"
          ><v-icon large color="green" class="mt-n2 ml-2">mdi-check</v-icon
          ><span class="white--text">saved</span></template
        >

        <v-spacer />

        <v-toolbar-title class="white--text headline font-weight-normal  mr-12"
          ><div v-if="clientProfile.type == 'Individual'">
            {{ clientAccHolder1 }}
          </div>
          <div v-else>
            {{ clientName }}
          </div>
        </v-toolbar-title>
      </v-toolbar>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { authComputed } from '@/vuex/helpers.js'

export default {
  name: 'theClientLayoutHeader',

  props: {
    headline: { type: String }
  },

  data() {
    return {
      loadingSuccess: false,
      showButton: true
    }
  },

  watch: {
    // watch page change (headline change) and remove save button
    // this prevents showing the button on the wrong page after navigating away with unsaved changes
    headline: 'removeButton'
  },

  methods: {
    removeButton() {
      this.showButton = false
    },

    updateItem() {
      // update goal (proposed data only) in DDB and state
      this.$emit('confirmChanges')
      this.loadingSuccess = true
      setTimeout(() => (this.loadingSuccess = false), 3500)
    }
  },

  computed: {
    dataChanged: {
      get() {
        return this.system.dataChanged
      },
      set(dataChanged) {
        this.$store.commit('SET_DATA_CHANGED', dataChanged)
      }
    },

    ...mapState(['system']),

    ...authComputed
  }
}
</script>

<style lang="scss" scoped></style>
