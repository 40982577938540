<template>
  <div>
    <v-dialog persistent v-model="dialog" max-width="400px">
      <v-card class="subtitle-1">
        <v-card-text
          class="title font-weight-medium primary--text text-center pt-4"
        >
          <p>Do you really want to leave?</p>
          <p class="subtitle-2 mt-n2">
            You have unsaved {{ pageNameFrom }} changes!
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            outlined
            class="primary--text ml-2 mb-4"
            text
            small
            @click="discardChanges"
            >Discard changes</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="success text--white"
            class="mr-2 mb-4"
            small
            @click="saveChanges"
            >Save changes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { authComputed } from '@/vuex/helpers.js'

export default {
  name: 'DialogSaveDiscardChanges',

  data() {
    return {
      dialog: false,
      resolve: null,
      pageNameFrom: ''
    }
  },

  methods: {
    async show(pageNameFrom) {
      return new Promise(resolve => {
        this.resolve = resolve
        this.pageNameFrom = pageNameFrom.name
        this.dialog = true
      })
    },
    saveChanges() {
      this.dialog = false
      this.resolve && this.resolve('save')
    },
    discardChanges() {
      this.dialog = false
      this.resolve && this.resolve('discard')
    }
  },

  computed: {
    ...mapState(['clientLedger']),

    ...authComputed
  }
}
</script>

<style lang="scss" scoped></style>
